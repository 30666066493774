<!--
 * @Descripttion: 哲学页面
 * @Author: 张明尧
 * @Date: 2021-02-24 09:13:48
 * @LastEditTime: 2021-03-23 09:29:27
-->
<template>
  <div class="philosophy-div">
    <div class="philosophy-main">
      <div class="philosophy-title">
        <div>一切皆可分解、一切皆可量化</div>
        利用数据的火炬点燃员工的激情是企业生命常青之源！！！
      </div>
      <!-- <div class="philosophy-banner">
      </div> -->
      <div class="philosophy-list-div">
        <el-row>
          <el-col v-for="(listData, _listData) in list" :key="_listData" :span="listData.allwidth && 24 || 12">
            <div :style="_listData == listCut && 'color: #0358FD;font-weight: 600'"  @click="checkMain(_listData)" class="philosophy-list-title">
              <div class="philosophy-list-left">
                <div :style="listData.color && `background: ${listData.color}`" class="philosophy-list-left-main"></div>
              </div>
              <span class="textOmit">{{ listData.title }}</span>
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="scrollView" style="padding-top: 60px">
        <Main :listCut="listCut" />
      </div>
      <div class="philosophy-footer">
        <div @click="changeListCut(listCut-1)" v-if="listCut > 0" class="philosophy-footer-text">{{list[listCut-1].title}}</div>
        <div @click="changeListCut(listCut+1)" v-if="listCut < list.length - 1" class="philosophy-footer-text">{{list[listCut+1].title}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Datas from './js/common';
import Main from './compnents/main'
export default {
  components: {
    Main,
  },
  data() {
    return {
      list: Datas.list_datas,
      listCut: 0,
    }
  },
  methods: {
    checkMain(index) {
      this.listCut = index;
      setTimeout(() => {
        const element = document.getElementById("scrollView");
        element.scrollIntoView({behavior:'smooth', block: 'start'}); 
      }, 20);
    },
    changeListCut(index) {
      this.listCut = index;
      setTimeout(() => {
        const element = document.getElementById("scrollView");
        element.scrollIntoView({behavior:'smooth', block: 'start'}); 
      }, 20);
    }
  }
}
</script>

<style lang="scss" scoped>
  .philosophy-div {
    min-height: 100vh;
    .textOmit {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      display: inline-block;
      vertical-align: middle;
    }
    .philosophy-main {
      width: 1300px;
      margin: 0 auto;
      padding-top: 50px;
      .philosophy-title {
        color: #3f3f3f;
        font-size: 38px;
        font-weight: 600;
      }
      .philosophy-footer-text {
        margin-bottom: 30px;
        font-size: 18px;
        font-weight: 500;
        color: #0358FD;
        line-height: 40px;
        text-decoration: underline;
        cursor: pointer;
      }
      .philosophy-banner {
        width: 100%;
        height: 280px;
        margin: 30px 0;
        border-radius: 10px;
        background: rgb(185,209,235);
      }
      .philosophy-list-div {
        margin-top: 60px;
        background: #F7FAFE;
        border: 1px dashed #8D98AB;
        border-radius: 6px;
        padding: 10px 0px 30px 40px;
        .philosophy-list-title {
          height: 50px;
          line-height: 50px;
          color: #3f3f3f;
          font-size: 16px;
          position: relative;
          cursor: pointer;
          &:hover {
            color: #0358FD
          }
          .philosophy-list-left {
            position: absolute;
            left: -40px;
            height: 50px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            .philosophy-list-left-main {
              background: red;
              height: 10px;
              width: 10px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
</style>