/*
 * @Descripttion: 组件公用的变量导出
 * @Author: 张明尧
 * @Date: 2021-02-22 15:46:28
 * @LastEditTime: 2021-03-18 16:26:03
 * 
 */
const list_img = {
  hxmk1: require('@/assets/ktdn/sy/1.png'),
  hxmk2: require('@/assets/ktdn/sy/2.png'),
  hxmk3: require('@/assets/ktdn/sy/3.png'),
  hxmk4: require('@/assets/ktdn/sy/4.png'),
  hxmk5: require('@/assets/ktdn/sy/5.png'),
  hxmk6: require('@/assets/ktdn/sy/6.png'),
  hxmk7: require('@/assets/ktdn/sy/7.png'),
  hxmk8: require('@/assets/ktdn/sy/8.png'),
  hxmk9: require('@/assets/ktdn/sy/9.png'),
  zx1_1: require('@/assets/ktdn/zx/1-1.png'),
  zx1_2: require('@/assets/ktdn/zx/1-2.png'),
  zx1_3: require('@/assets/ktdn/zx/1-3.png'),
  zx1_4: require('@/assets/ktdn/zx/1-4.png'),
  zx1_5: require('@/assets/ktdn/zx/1-5.png'),
  zx1_6: require('@/assets/ktdn/zx/1-6.png'),
  zx2_1: require('@/assets/ktdn/zx/2-1.png'),
  zx3_1: require('@/assets/ktdn/zx/3-1.png'),
  zx3_2: require('@/assets/ktdn/zx/3-2.png'),
  zx3_3: require('@/assets/ktdn/zx/3-3.png'),
  zx3_4: require('@/assets/ktdn/zx/3-4.png'),
  zx3_5: require('@/assets/ktdn/zx/3-5.png'),
  zx3_6: require('@/assets/ktdn/zx/3-6.png'),
  zx4_1: require('@/assets/ktdn/zx/4-1.png'),
  zx4_2: require('@/assets/ktdn/zx/4-2.png'),
  zx4_3: require('@/assets/ktdn/zx/4-3.png'),
  zx4_4: require('@/assets/ktdn/zx/4-4.png'),
  zx4_5: require('@/assets/ktdn/zx/4-5.png'),
  zx5_1: require('@/assets/ktdn/zx/5-1.png'),
  zx6_1: require('@/assets/ktdn/zx/6-1.png'),
  zx6_2: require('@/assets/ktdn/zx/6-2.png'),
  zx6_3: require('@/assets/ktdn/zx/6-3.png'),
  zx7_1: require('@/assets/ktdn/zx/7-1.png'),
  zx7_2: require('@/assets/ktdn/zx/7-2.png'),
  zx7_3: require('@/assets/ktdn/zx/7-3.png'),
  zx8_1: require('@/assets/ktdn/zx/8-1.png'),
  zx9_1: require('@/assets/ktdn/zx/9-1.png'),
  zx9_2: require('@/assets/ktdn/zx/9-2.png'),
  zx9_3: require('@/assets/ktdn/zx/9-3.png'),
  zx9_4: require('@/assets/ktdn/zx/9-4.png'),
  zx10_1: require('@/assets/ktdn/zx/10-1.png'),
  zx10_2: require('@/assets/ktdn/zx/10-2.png'),
  zx10_3: require('@/assets/ktdn/zx/10-3.png'),
  zx11_1: require('@/assets/ktdn/zx/11-1.png'),
  zx11_2: require('@/assets/ktdn/zx/11-2.png'),
  zx12_1: require('@/assets/ktdn/zx/12-1.png'),
  zx12_2: require('@/assets/ktdn/zx/12-2.png'),
  zx13_1: require('@/assets/ktdn/zx/13-1.png'),
  zx13_2: require('@/assets/ktdn/zx/13-2.png'),
  zx13_3: require('@/assets/ktdn/zx/13-3.png'),
  zx14_1: require('@/assets/ktdn/zx/14-1.png'),
  zx14_2: require('@/assets/ktdn/zx/14-2.png'),
  zx14_3: require('@/assets/ktdn/zx/14-3.png'),
  zx14_4: require('@/assets/ktdn/zx/14-4.png'),
  zx15_1: require('@/assets/ktdn/zx/15-1.png'),
  zx16_1: require('@/assets/ktdn/zx/16-1.png'),
  zx18_1: require('@/assets/ktdn/zx/18-1.png'),
};
const Datas = {
  // 哲学中的灵魂拷问list
  // dataStyle[title: 标题样式, text: 普通文字样式, img: 图片样式]
  list_datas: [
    {
      title: '灵魂拷问1：你能准确的估算出销售商机的成交几率吗？',
      color: '#0358FD',
      content: [
        {
          main: '制定销售指导方案，指导及规范销售人员日常工作。',
          dataStyle: 'title',
        },
        {
          main: `公司根据销售人员日常工作进行总结归纳，萃取出销售人员工作指导方案，销售人员入职后需先学习销售员工指导方案，包括：线索、客户、商机、洽谈、成交五部分内容，每部分内分为：准备工作、例行工作、重点工作等。通过销售指导方案不但可以指导新销售人员快速融入公司工作，而且方案的细节点形成了销售人员绩效考核的关键指标，以及形成销售商机评分测评卡的评分要点。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx1_1,
          dataStyle: 'img',
        },
        {
          main: '准确评估销售商机成交几率。制定企业标准成交几率模型，运用商机成交几率测评卡。',
          dataStyle: 'title',
        },
        {
          main: `步骤1：搭建企业成交几率标准模型。企业大脑提供了一套标准的销售商机成交几率模型，分为三个大类，分别是：客户情报、激发欲望、诱导行动。客户情报是围绕客户信息、项目信息收集，收集信息包括：对接主体、对接人职位、项目阶段、谈判方式、资质应答、竞争分析等。激发欲望是围绕客户关系、产品展示、方案制作方面的评测，评测内容包括：培养教练、客户公关、成交信号、宣传展示、产品体验、商务方案、技术方案。诱导行动是对销售过程中的可采用的指导方案，主要包括商务诱导、技术诱导。各类评测指标中，计算方式可分为排他算法、条件累加，并可要求部分指标必须给出评分依据，填写此依据可以让销售人员更准确的分析成交率，并将情况真实反馈给公司领导。使成交几率测评更科学更准确。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx1_2,
          dataStyle: 'img',
        },
        {
          main: `步骤2：商机成交几率测评卡。测评卡分为客观算法、人工主观算法，可根据灵活多变的市场情况进行选择。客观算法适用于新客户新商机，采用客观填写评分的方式进行商机测评。客观算法包括：核心标准评分、客户健康评分两部分构成，并且可以查看各次修改记录及评分趋势。核心标准中不但有收集客户信息的评测项，还可以通过各个评测项指导销售人员解决遇到的难题，客户健康则是根据客户沟通的状态进行评分，在跟进商机中，客户健康状态往往起着关键性作用。修改记录可以看到每次评分的变化及变化细节。报告分析则可以提前预知风险，发现分数下降趋势，则马上分析原因，采取对策。人工主观算法适用于老客户二次成交的商机，与客户粘性较高，客户信息已掌握，相互已建立了良好的信任，可直接根据销售经验进行人工评分，填写评分依据。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx1_3,
          dataStyle: 'img',
        },
        {
          main: list_img.zx1_4,
          dataStyle: 'img',
        },
        {
          main: list_img.zx1_5,
          dataStyle: 'img',
        },
        {
          main: '通过成交趋势预测图表，进行销售决策。',
          dataStyle: 'title',
        },
        {
          main: `在销售过程中，能准确的记录商机真实情况，以及预测未来变化是尤为重要的。销售预测图表真实的记录了商机的执行情况以及未来的商机趋势，是领导进行下一步工作决策的依据。水平线上方的数据为预计本月成交的总额，包括两部分，分别是：橙色展示本月预计成交商机额，红色是其它月转入到本月的预计成交商机额，水平线下方的数据为本月不会成交的总额，包括两部分，分别是：橙色为本月转出到其他月的商机额，浅蓝色表示本月未成交商机额（输单、关闭的商机额）。蓝色折线表示的是本月签订了合同的实际成交额。通过柱状图和成交折线对比，可以分析出历史的成交几率，也可以看出未来预计成交额趋势，若未来预计成交额趋势下降，则需要加大拓客力度，提高预计商机额，若预计成交额持续增长，则需要加大销售力度，提高实际成交率。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx1_6,
          dataStyle: 'img',
        },
      ]
    },
    {
      title: '灵魂拷问2：你真的懂客户组织画像吗？',
      color: '#0358FD',
      content: [
        {
          main: '收集客户完整组织机构图，标清干系人角色，帮你摸清客户的底。',
          dataStyle: 'title',
        },
        {
          main: `步骤1：四种方法帮你收集客户组织机构图，第一，对客户进行网络搜索。第二，深入研究客户的企业网站。第三、研究客户企业内部刊物。第四，向相关人员打听。<br>
          步骤2：将收集的组织机构图信息维护到系统中，并且标识各位干系人对本公司的角色。联系人身份分为：决策者、使用者、技术把关者。`,
          dataStyle: 'text',
        },
        {
          main: [
            {
              name: '决策者：',
              text: '拥有最终决策权的人，最后拍板的人，即使其他的人都反对，他也可以作出决定，所以十分重要。'
            },
            {
              name: '使用者：',
              text: '直接使用产品的人，对产品有直接评价权。'
            },
            {
              name: '技术把关者：',
              text: '技术把关者不能说“YES”，但可以说“NO”。可以将他认为不合格的供应商踢出局，因此对产品有否决权。联系人作用分为：教练、地雷、两面派。'
            },
            {
              name: '教练：',
              text: '即客户企业中的内线，帮助销售人员获得信息、联系方式以及确认其他销售影响者，时刻指导销售人员的销售定位，至关重要。'
            },
            {
              name: '地雷：',
              text: '阻碍销售成交的人，或对产品提反对意见的人。'
            },
            {
              name: '两面派：',
              text: '客户中对于交易没有明确表态或是态度摇摆不定的人。将收集好的联系人根据当前已知信息，打好标签。'
            },
          ],
          dataStyle: 'array',
        },
        {
          main: '利用组织机构图，帮你知己知彼才能百战百胜。',
          dataStyle: 'title',
        },
        {
          main: `根据维护好的组织机构图，清晰的找到在此商机中，客户各个部门的联系人对此商机的影响，真正的做到知己知彼，通过教练将决策者、使用者、技术把关者转为自己的教练，排除地雷人物，稳住两面派人物，最终即可做到百战百胜。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx2_1,
          dataStyle: 'img',
        },
      ]
    },
    {
      title: '灵魂拷问3：你真的知道你的销售是如何工作的吗？',
      color: '#FF4738',
      content: [
        {
          main: '销售外勤打卡，轻松了解销售人员所在位置。',
          dataStyle: 'title',
        },
        {
          main: `移动端的外勤打卡，可以随时随地进行签到签退，实时记录所处的地理位置坐标。外勤打卡可以关联外出申请事项，关联所拜访的客户。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx3_1,
          dataStyle: 'img',
        },
        {
          main: '销售记录追踪，完整的记录客户商机每一次跟进情况。',
          dataStyle: 'title',
        },
        {
          main: `针对客户、线索、商机都可以进行登记跟进记录，记录每一次与客户对接的细节内容。在销售商机中，可以方便看到最后跟进时间，方便定位需要尽快跟进的商机。根据跟进记录可以分析出销售过程是否出现风险，及时纠偏。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx3_2,
          dataStyle: 'img',
        },
        {
          main: list_img.zx3_3,
          dataStyle: 'img',
        },
        {
          main: '销售工时分析，用数据化方式让销售工作一目了然。',
          dataStyle: 'title',
        },
        {
          main: `销售人员在日常工作中，需要使用员工工作台记录每天所做的销售任务，以创建任务、领取任务、启动的任务的方式，真实记录所有销售任务。通过产能工作明细对销售工时进行分析，从而优化销售工作，提高销售效率。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx3_4,
          dataStyle: 'img',
        },
        {
          main: list_img.zx3_5,
          dataStyle: 'img',
        },
      ]
    },
    {
      title: '灵魂拷问4：你能灵活运用全员营销的管理模型吗？',
      color: '#FF4738',
      content: [
        {
          main: '销售四连环即是目标一致的全员营销。',
          dataStyle: 'title',
        },
        {
          main: `销售四连环是将公司内部的销售、销售经理、技术/高管人员、服务人员有机的结合在一起，以客户成交为统一目标，让专业的人办专业的事，快速响应客户需求，多方面攻克客户关键人物，以全员营销的方式达到快速成交。销售四连环是销售人员在跟进商机过程中，遇到高层商务会谈，技术交流会议，后续服务模式对接，需要各个领域的专家来参与协同解决，因此，员营销非常重要。`,
          dataStyle: 'text',
        },
        {
          main: '搭建以销售为主导，协调销售经理、技术、高管、服务人员的销售四连环。',
          dataStyle: 'title',
        },
        {
          main: `在企业内部由高管人员宣讲销售四连环全员营销理念，让企业每个人都要有营销理念，营销不仅仅是销售人员的工作，而且企业每个人的工作。在销售四连环中，销售人员拥有对参与人的评分、分配奖金权力，让企业全员销售走向正规。销售四连环由销售人员、销售经理、技术/高管、服务组成了四个环，各自环中对应放入公司内的人员。销售人员根据商机情况，发起四连环及奖金申请，并邀请对应环节的人员参与，被邀请人可根据实际工作情况，进行认领、拒绝、分配该邀请，认领的四连环任务，既要全力完成任务，并及时反馈与客户跟进内容。在商机成交后，销售人员会对四连环参与人进行评分及奖金分配，最终结束本次四连环协同工作。`,
          dataStyle: 'text',
        },
        {
          main: '销售四连环帮助企业在白热化的竞争中脱颖而出，加速签单。',
          dataStyle: 'title',
        },
        {
          main: `销售四连环是以企业全员营销方式，完成每一次客户成交。不能靠销售个人力量，要靠方法和工具，让企业营销管理进入规范化，形成狼一样的团队，成功就要赢在比对手更高的成交率，提升管理的职业化是企业持续发展的不懈动力。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx4_1,
          dataStyle: 'img',
        },
        {
          main: list_img.zx4_2,
          dataStyle: 'img',
        },
        {
          main: list_img.zx4_3,
          dataStyle: 'img',
        },
        {
          main: list_img.zx4_4,
          dataStyle: 'img',
        },
        {
          main: list_img.zx4_5,
          dataStyle: 'img',
        },
      ]
    },
    {
      title: '灵魂拷问5：你的企业有销售知识库吗？',
      color: '#FCCE5C',
      content: [
        {
          main: '搭建企业销售知识库，积累销售过程资产。',
          dataStyle: 'title',
        },
        {
          main: `销售知识库的内容包括：公司各类宣传的资料、企业资质证书、业务场景案例、产品常见问题、报价相关资料、公司相关问题、以及指导销售人员拒绝处理的销售方法等。这些内容经过常年累月的积累，形成公司内部销售知识库，让销售工作更加得心应手。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx5_1,
          dataStyle: 'img',
        },
      ]
    },
    {
      title: '灵魂拷问6：你的销售真的会报价吗？',
      color: '#FCCE5C',
      content: [
        {
          main: '准确的成本估算是报价的基础。',
          dataStyle: 'title',
        },
        {
          main: `项目成本估算是报价的基础，缺少成本估算的报价，往往会出现公司亏损的风险。项目成本估算包括研发成本、实施成本、差旅成本、销售成本、采购成本五个方面进行估算。研发成本是将客户的需求分解，梳理出每个需求点对于的人工工期及成本，人员的岗位角色不同，人工成本也不同。实施成本是实施顾问现场实施所产生的人工成本，将明确的实施事项，对应上项目岗位人天及成本。差旅成本是研发人员和实施人员往返的差旅费用，包括：交通费、住宿费等，销售成本包括销售人员的招待费、会议费、差旅费等费用。采购成本是在本项目中，采购了第三方的产品或服务的费用。让报价掌握准确的成本，才能做出合理的报价。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx6_1,
          dataStyle: 'img',
        },
        {
          main: '经过精准测算的报价单，才是企业盈利的基础。',
          dataStyle: 'title',
        },
        {
          main: `报价单是基于准确的成本估算，加上企业内合理的利润率，形成的报价单。在企业中，对报价要有标准的工作规范，不同类型的报价，对应的利润率要有不同的要求。若不顾及利润率，会造成企业亏损的情况，导致企业无法继续经营。因此，在报价时，报价指标的测算就尤为重要。基于成本估算数据，加上企业通用利润率，可以快速得到报价金额，再根据实际情况，调整利润率、税率即可得到最终合理报价，以及预估出该项目的预期收益。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx6_2,
          dataStyle: 'img',
        },
        {
          main: '快速掌握报价工具，自动形成企业报价单。',
          dataStyle: 'title',
        },
        {
          main: `三步搞定报价工具，第一步：填写项目成本估算。第二步：创建报价单，报价金额测算，确定报价总额。第三步：编写报价单信息，执行流程审批后导出报价单。填写项目成本估算，从五个方面编写，分为是：研发成本、实施成本、差旅成本、销售成本、采购成本，编写完成后，进行统一汇总计算成本总额。报价单是基于项目成本估算进行测算报价金额的，调整项目和采购的利润率以及对应的税率，即可轻松得到报价总额。确定报价总额后，即可编写报价单的明细内容，明细内容包括报价单页眉、页脚、敬语、清单备注、付款方式、报价清单、以及清单对于的附录说明内容。填报审核后，即可导出正式的报价单。报价单可以发给客户在线查看和pdf版本。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx6_1,
          dataStyle: 'img',
        },
        {
          main: list_img.zx6_2,
          dataStyle: 'img',
        },
        {
          main: list_img.zx6_3,
          dataStyle: 'img',
        },
      ]
    },
    {
      title: '灵魂拷问7：你真的知道订单怎么管吗？',
      color: '#0358FD',
      content: [
        {
          main: '合同向上关联销售商机。',
          dataStyle: 'title',
        },
        {
          main: `合同签署前需要关注销售商机的执行过程，只有商机成交后，才会签署合同。订单合同必须与商机相关联。可以追溯合同从无到有的成交过程。`,
          dataStyle: 'text',
        },
        {
          main: '合同中间紧盯回款计划。',
          dataStyle: 'title',
        },
        {
          main: `合同执行过程中，需要紧盯合同的回款计划，关注回款时间，回款状态，是否存在逾期回款。一个企业是否正持续发展，不光光关注大量的成交商机，还要关注合同的真实回款情况，保证公司的现金流正常。逾期回款过多会导致公司无法正常经营，因此一定要紧盯回款计划，尤其是非健康状态的逾期回款。`,
          dataStyle: 'text',
        },
        {
          main: '合同项下追踪项目执行。',
          dataStyle: 'title',
        },
        {
          main: `为了确保正常合同回款，就必须要关注合同对于的项目执行情况，关注项目执行过程是否正常，是否存在巨大风险，是否按项目里程碑完成关键节点任务，尤其是针对合同回款计划的里程碑，必须保质保量完成，这样才能保证正常回款。`,
          dataStyle: 'text',
        },
        {
          main: '合同终止、变更应付突发情况。',
          dataStyle: 'title',
        },
        {
          main: `当合同执行出现意外终止或变更情况时，一定要在系统中对原有合同进行对于的调整，确保在线系统的信息与实际情况保持一致，这样才能真实的反应出公司的实际经营情况，也不会由于部分终止、变更内容影响整体的经营数据分析。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx7_1,
          dataStyle: 'img',
        },
        {
          main: list_img.zx7_2,
          dataStyle: 'img',
        },
        {
          main: list_img.zx7_3,
          dataStyle: 'img',
        },
      ]
    },
    {
      title: '灵魂拷问8：你对公司网站的运营数据关注过吗？',
      color: '#0358FD',
      content: [
        {
          main: '关注公司官方网站访问情况的数据，就是在关注公司的市场。',
          dataStyle: 'title',
        },
        {
          main: `公司网站往往是做完之后就挂在互联网上，企业内部很少有人再关注该网站。而实际来找公司的客户往往是通过公司网站了解公司，找到公司。无论是那种推广宣传方式，最终都会引流到公司网站。因此关注公司网站的运营数据是极其重要的。关注网站运营数据主要包括如下方面：访问时间、站点模块、停留时间、IP地址、省市区、进入网站的URL，通过这些数据，可以与市场无缝接轨，了解真实的市场反馈。`,
          dataStyle: 'text',
        },
        {
          main: '网站访问量的数据分析，助力公司市场决策。',
          dataStyle: 'title',
        },
        {
          main: `通过网站访问量的数据分析，可以对公司产品进行优化指导。新媒体推广方面，通过URL的数据，可以看出哪个推广渠道引流最多，可以加大推广力度，降低引流少的渠道投入，优化推广。通过访问时间、站点模块、停留时间可以得知客户在哪个时间段会集中访问到网站，对哪些产品模块感兴趣，停留时间长，这样可以优化产品生产方向。每日的数据趋势图表，可以观看到整个的访问趋势，适当调整新媒体推广力度，加大曝光度。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx8_1,
          dataStyle: 'img',
        },
      ]
    },
    {
      title: '灵魂拷问9：你真的懂项目管理吗？',
      color: '#FF4738',
      content: [
        {
          main: '项目计划推进与资源配置是项目成功的关键。',
          dataStyle: 'title',
        },
        {
          main: `项目管理五大过程组分别为：启动过程组、规划过程组、执行过程组、监控过程组、收尾过程组。在五大过程组中，项目计划推进与资源配置是项目成功的关键。在项目立项过程中，项目经理进行了项目的工作计划安排，配置了项目成员。按需将工作计划分配给各个负责人，通过项目任务单的方式下发给各个项目团队成员，在项目执行过程中，通过项目缺陷单的方式记录着各个任务的变更、优化、缺陷内容，按计划合理的推荐整个项目的执行，并且通过任务单、缺陷单使得项目执行过程有数据记录。在团队成员员工工作台领取任务，启动任务时，每一项任务都会记录任务开始和结束时间，真实的记录着项目的所耗工时，使得整个项目在执行过程中，都可以用数据量化，减少了项目隐藏风险。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx9_1,
          dataStyle: 'img',
        },
        {
          main: list_img.zx9_2,
          dataStyle: 'img',
        },
        {
          main: list_img.zx9_3,
          dataStyle: 'img',
        },
        {
          main: list_img.zx9_4,
          dataStyle: 'img',
        },
      ]
    },
    {
      title: '灵魂拷问10：你真的知道员工每天全干了些什么吗？',
      color: '#FF4738',
      content: [
        {
          main: '真正的将工时管理应用到日常工作中。',
          dataStyle: 'title',
        },
        {
          main: `员工工作台是每位员工记录工时的得力助手，每天将领导安排的工作任务，其他同事需要协同解决的工作，以及自己创建的岗位任务领取到工作台，点击任务启动按钮，即可开始做一个工作任务并记录该项任务的工时，暂停或结束时，会自动暂停记录该任务工时。在午休和下班时，系统会自动停止记录工时，并通知告知，若你在继续工作需点击启动。`,
          dataStyle: 'text',
        },
        {
          main: '通过员工工作台，规范员工工作行为。',
          dataStyle: 'title',
        },
        {
          main: `员工工作台不但能记录工时，还可以查看今日工时时长，记录本人已耗工时，可以方便看出本日工时是否达到公司标准。分析报表可以查看本月每日的工时趋势情况，可以看到公司人员、部门人员的工时平均线，找到自己和他人的差距，自我提醒自己改善工作行为。我的产能可以查看到本人每天的产出成果，以及产出成果对于的单日所耗工时。`,
          dataStyle: 'text',
        },
        {
          main: '真实的工时记录带来了精准成本核算。',
          dataStyle: 'title',
        },
        {
          main: `真实的工时记录可以汇总出每个任务、每个模块、每个项目的真实工时，通过工时与人工成本，可以更精准的进行成本核算，以往一人多项目时，很难分清楚每个人在每个项目上每天所耗费的工时，通过工时管理系统即可解决此问题，科学的记录让成本核算更精准。`,
          dataStyle: 'text',
        },
        {
          main: '合理化分配企业生产力量。',
          dataStyle: 'title',
        },
        {
          main: `通过产能以及对应的工时，可以分析出各个项目各个任务的生产情况。可以通过与进度计划的对比，合理的分配企业生产力量，加大整个公司的产能，杜绝浪费资源，提升公司营业业绩。`,
          dataStyle: 'text',
        },
        {
          main: '辅助公司战略调整。',
          dataStyle: 'title',
        },
        {
          main: `通过工时管理，可以清晰的分析出员工每天干了什么，每个员工、每个部门的能效是多少，这样可以辅助公司对生产力进行整体优化，生产力的提升可以加大市场的接单率，调整公司战略。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx10_1,
          dataStyle: 'img',
        },
        {
          main: list_img.zx10_2,
          dataStyle: 'img',
        },
        {
          main: list_img.zx10_3,
          dataStyle: 'img',
        },
      ]
    },
    {
      title: '灵魂拷问11：你能准确的计算出团队的产能吗？',
      color: '#FCCE5C',
      content: [
        {
          main: '真实的数据让你了解公司的产能。',
          dataStyle: 'title',
        },
        {
          main: `员工工作台记录了每位员工的真实工时数据，通过工时数据与启动任务的记录，可以准确的获得每个任务完成所耗的工时，既而可以计算出每个任务的产能，通过此方式，可以算出每位员工的日产能、周产能、月产能，可以算出每个团队的各类产能，可以获得某个项目团队的产能，产能高的团队战斗力较强，能在节省人工成本的情况下，快速高效的完成各项任务。`,
          dataStyle: 'text',
        },
        {
          main: '产能是衡量团队战斗力的唯一标准。',
          dataStyle: 'title',
        },
        {
          main: `产能数据表示着一个团队的战斗力，单位时间内，完成任务量最多的团队即是高产能的团队。在一个企业中，若所有团队都是高产能团队，那在各类竞争对手中，将是一件很可怕的事情。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx11_1,
          dataStyle: 'img',
        },
        {
          main: list_img.zx11_2,
          dataStyle: 'img',
        },
      ]
    },
    {
      title: '灵魂拷问12：你公司的文件还散落在员工的电脑里吗？',
      color: '#FCCE5C',
      content: [
        {
          main: '公司文件必须集中管理。',
          dataStyle: 'title',
        },
        {
          main: `公司文件是公司的组织过程资产，对于公司范围内所产生的一切文档，都应该集中进行管理。公司的文件包括：公司体系建设、项目管理、销售管理、人力管理、财务管理、法务管理等等各方面的文件。各类文件的集中管理，不会由于人员流动而流失。`,
          dataStyle: 'text',
        },
        {
          main: '公司文件必须要受权限控制。',
          dataStyle: 'title',
        },
        {
          main: `公司文件必须要受权限控制，各个业务之间，各个部门之间的文件权限必须是独立划分的，不能互相之间查看到与本岗位无关的文件，避免造成重要机密文件的流失。文件夹自身也多级权限，例如：1、可管理、编辑文件，2、可编辑文件，3、仅可查看、下载文件，4、仅可查看文件，四个权限级别。每一级文件夹可以控制下级各个文件夹的权限。分配方式分为：全公司、指定公司、指定部门、指定角色、指定人员。使文件权限控制的更加灵活。`,
          dataStyle: 'text',
        },
        {
          main: '网盘的使用。',
          dataStyle: 'title',
        },
        {
          main: `企业大脑网盘分为公司文件、个人文件两大类。公司文件支持各级授权方式，每级文件夹可以按四种级别授权，分别是：1、可管理、编辑文件，2、可编辑文件，3、仅可查看、下载文件，4、仅可查看文件。每个级别可以按：全公司、指定公司、指定部门、指定角色、指定人员，进行灵活授权。个人文件则为个人提供的网盘，可以建立个人的文件目录，上传个人文件。公司文件和个人文件都可以进行分享，分享的内容可以根据个人的权限来控制是否可查看内容。打标签操作是方便个人按自己的习惯标记文件，查找文件。网盘还支持了拖拽的方式上传文件及文件夹，大大提高了使用效果。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx12_1,
          dataStyle: 'img',
        },
        {
          main: list_img.zx12_2,
          dataStyle: 'img',
        },
      ]
    },
    {
      title: '灵魂拷问13：你的HR真能管理好员工吗？',
      color: '#0358FD',
      content: [
        {
          main: '年假有多种发放规则，公司年到底怎么发。',
          dataStyle: 'title',
        },
        {
          main: `年假的规则可以按入职周年发放、按转正周年发放、按自然年发放。计算的标准可以按司龄、按工龄计算，享受资格也可划分为：在职人员、入职满一年、转正满一年。清零方式分为不清零、周期日清零、固定日清零。对于一个企业来说，选择哪种年假发放规则都是合理的，需要清晰的知道年假的发放人员范围、周期、清零周期即可。`,
          dataStyle: 'text',
        },
        {
          main: '各种各样的假期类型，员工应该怎么请假。',
          dataStyle: 'title',
        },
        {
          main: `带薪假期类型包括：年假、婚假、产假、丧假、串休、轮休假、陪产假、福利假。可以根据公司的特性增加假期类型，但是每种假期类型的休假方式是不同的。年假是由公司每年按规则发放的，只有发放了年假，员工在请假时才能选择年假。婚假、产假、陪产假、丧家，都是由公司根据员工申请情况，定向给部分员工进行发放，根据劳动法规则，发放指定天数的假期。串休为员工加班后选择的补偿方式，可以在串休时进行使用。轮休假为公司为长期人员提供的假期。福利假是每年妇女节、青年节、儿童节等为部分符合条件的员工放的假，因此这些类型都属于带薪休假。不带薪休假类型包括：事假、病假等，这些员工在请假时，只需直接申请假期即可。病假需要提供医院诊断证书，即可获得补助金。`,
          dataStyle: 'text',
        },
        {
          main: '加班的补偿方式，加班费还是串休。',
          dataStyle: 'title',
        },
        {
          main: `加班的补偿方式可以进行选择加班费或串休两种模式，加班费会在下一个月进行核算后发放到对应的工资中，串休则可以在请假时，进行抵扣使用。`,
          dataStyle: 'text',
        },
        {
          main: '摆脱excel统计考勤，系统自动计算每日考勤。',
          dataStyle: 'title',
        },
        {
          main: `考勤计算不应该留到计算工资时才进行计算，而应该每日自动计算前一天的考勤情况，及时查看每位员工的考勤数据。通过指纹打卡机、移动端的外勤打卡等方式，记录签到签退的时间，结合各类请假类型可以自动计算出每日的考勤数据。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx13_1,
          dataStyle: 'img',
        },
        {
          main: list_img.zx13_2,
          dataStyle: 'img',
        },
        {
          main: list_img.zx13_3,
          dataStyle: 'img',
        },
      ]
      
    },
    {
      title: '灵魂拷问14：你不会还用Excel算工资吧？',
      color: '#0358FD',
      content: [
        {
          main: '动态薪资组、动态工资条，灵活适用一企多岗的算薪模式。',
          dataStyle: 'title',
        },
        {
          main: `公司内部由于岗位职责不同，会划分多个薪资组，每个薪资组可以根据特有的特点进行单独的数据计算规则维护，调整出不同的工资条。在薪资组中，需要关联上本组的员工，配置本组所使用的数据项，以及各项的计算公式，可以进行灵活调整。`,
          dataStyle: 'text',
        },
        {
          main: '算薪自动关联考勤，补扣不迷糊。',
          dataStyle: 'title',
        },
        {
          main: `在薪资组的计算规则中，定义了与考勤模块关联的数据项，可以获取到本组员工的考勤数据，包括：迟到、早退、请假、出差、外出、工作日等考勤数据，为计算工资提供考勤依据。无限人工计算考勤数据。`,
          dataStyle: 'text',
        },
        {
          main: '工资计算，一秒完成。',
          dataStyle: 'title',
        },
        {
          main: `工资计算功能，选择年度、月度、开始日期、结束日期、选择薪资组，点击【薪资计算】按钮，即可完成薪资计算。然后通过工资明细、五险金明细、公积金明细查看每位员工的薪资数据，并且可以查看到公司对应支付的数据。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx14_1,
          dataStyle: 'img',
        },
        {
          main: list_img.zx14_2,
          dataStyle: 'img',
        },
        {
          main: list_img.zx14_3,
          dataStyle: 'img',
        },
        {
          main: list_img.zx14_4,
          dataStyle: 'img',
        },
      ]
    },
    {
      title: '灵魂拷问15：你真的懂OKR吗？',
      color: '#FF4738',
      content: [
        {
          main: '诚实守信才是OKR的哲学。',
          dataStyle: 'title',
        },
        {
          main: `OKR（Objectives and Key Results）即目标与关键成果法，是一套明确和跟踪目标及其完成情况的管理工具和方法。诚实守信是每日的员工OKR，由员工每日记录本人是否完成前一日所制定的目标计划，并且真实反应当日的成果，最后再制定出第二天的工作目标计划。由领导来进行审核员工的执行结果。衡量的标准如下：今日之星（5分）：工作计划全部兑现，8小时工作饱满且成果显著，且超额完成计划内工作内容。诚实守信（3分）：工作计划全部兑现，8小时工作饱满且成果显著。有情可原（2分）：由于临时工作安排问题导致未完成计划内的全部工作，且当日工作成果饱满有效。言而无信（1分）：未完成计划内的部分工作。毫无进展（0分）：当日工作毫无成果，或成果微乎其微。通过统一的标准，即可将OKR最有效的应用到企业内部。通过此方式，大大提高了员工制定工作计划，并按计划完成的能力，提高了整体的产能。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx15_1,
          dataStyle: 'img',
        },
      ]
    },
    {
      title: '灵魂拷问16：你知道个性指标才是绩效考核的灵魂吗？',
      color: '#FF4738',
      content: [
        {
          main: '掌握平衡计分卡BSC的基本操作。',
          dataStyle: 'title',
        },
        {
          main: `使用平衡计分卡BSC，首先要维护好公司内的考核岗位设定、数据项设定、关键指标设定，公司高管和HR一起制定出公司通用的考核内容。然后再创建平衡计分考核计划，选择考核的周期期间，选择参与考核的人员，导入基础指标、岗位指标，下发各个负责人维护个性指标，即可开启考核，评分结束后可以查看评分结果。`,
          dataStyle: 'text',
        },
        {
          main: '使用个性指标的BSC才有灵魂。',
          dataStyle: 'title',
        },
        {
          main: `在平衡计分考核计划中，个性指标是每个人都不同的指标，针对每个人直属领导给定制了专属的考核目标，这样才能更有效的考核每位员工的真实工作成果，没有千篇一律的执行考核形式。只有包含个性考核指标的BSC才是有灵魂的绩效考核。`,
          dataStyle: 'text',
        },
        {
          main: '绩效考核要和薪酬挂钩。',
          dataStyle: 'title',
        },
        {
          main: `绩效考核是为了更好的提高员工个人能力，更好的提高公司战斗力，因此，绩效考核要与薪酬挂钩，以实际的钱的方式，展示出个人贡献的差异。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx16_1,
          dataStyle: 'img',
        },
      ]
    },
    {
      title: '灵魂拷问17：你的企业文化仅仅是挂到墙上的一句话吗？',
      color: '#FCCE5C',
      content: [
        {
          main: '企业文化融到公司日常工作中才有价值。',
          dataStyle: 'title',
        },
        {
          main: `企业文化往往都是贴在墙上的标语，例如：“客户至上”、“团结”、“创新”等等。但往往仅停留在一种口号上，无法与日常工作融合。我们企业的文化是客户之声、感动顾客、问题变智慧、感恩有你、感动事迹，这些功能是真正的将文化融到工作中。顾客之声、感动顾客就是“客户至上”，问题变智慧就是将解决问题的方法记录下来，也就是企业内部“创新”，感恩有你、感动事迹其实就是企业“团结”的表现，将这么多内容都在系统中一一登记，并采用BSC考核指标的方式记录得分，当文化与考核指标挂钩后，即可让口号不挂在墙上，真实有效的融入到了日常工作。`,
          dataStyle: 'text',
        },
        {
          main: '员工只愿意做你考核的，而不是做你倡导的。',
          dataStyle: 'title',
        },
        {
          main: `员工在日常工作中，往往会根据个人的习惯不同，出现各种各样的工作习惯。在大多数的企业中，发现只有公司制定了某些制度，员工才会去遵守、去执行，往往是公司提出的引导思路，起不到预期想要的结果。因此，企业可以根据实际情况，将希望员工做到的事项已考核的方式，进行引导员工去执行，从而提高员工的整体能力。`,
          dataStyle: 'text',
        },
      ]
    },
    {
      title: '灵魂拷问18：你知道给员工一个自我展示的窗口多重要吗？',
      color: '#FCCE5C',
      content: [
        {
          main: '冒泡的使用。',
          dataStyle: 'title',
        },
        {
          main: `冒泡是公司内部互动交流的有效工具。可以将个人的感受、所见所闻，快速的分享给大家，大家也可以通过点赞和回复的方式进行交流。冒泡就是企业内部的同事圈。`,
          dataStyle: 'text',
        },
        {
          main: '活跃公司气氛。',
          dataStyle: 'title',
        },
        {
          main: `一个公司的气氛，就是看大家在公司内部是否可以展示出自我，只有热爱公司、积极展示自我的人，才是公司团队中真正的一份子，活跃公司气氛，打造完美团队，是每个人都需要奉献力量的，冒泡即可在工作之余让所有人互相更加了解。`,
          dataStyle: 'text',
        },
        {
          main: '促进团队融合',
          dataStyle: 'title',
        },
        {
          main: `在冒泡的交流中，除了工作之外的内容，让大家更能多了解一个人的多面性，更知道原来团队中有这么多志同道合的人，无形中让团队的凝聚力倍增。`,
          dataStyle: 'text',
        },
        {
          main: list_img.zx18_1,
          dataStyle: 'img',
        },
      ]
    },
    {
      title: '灵魂拷问19：你知道以考促学才能员工提升能力的最优路径吗？',
      color: '#0358FD',
      content: [
        {
          main: '在线学习系统，增长个人能力。',
          dataStyle: 'title',
        },
        {
          main: `每个公司都会有属于公司专属的知识沉淀，在线的学习管理模块，即可维护企业内部的课程、培训计划，让每位员工在我的培训中进行学习，增长个人能力。在线学习可以记录每个人的所学内容、已学数量、文档数、文章数、以及学习的具体时间。`,
          dataStyle: 'text',
        },
        {
          main: '在线考试系统，证明个人能力。',
          dataStyle: 'title',
        },
        {
          main: `在线考试系统，是证明个人能力最有力的证明。在线考试管理模块，提供了学科管理、题库管理、试卷管理、考试计划、我的考试、自主练习、老师阅卷、成绩管理的全链条功能。方便企业对内部员工进行各类的考核试卷工作。帮助企业人力资源更好的了解每名员工的能力水平，以及定岗定级的标准。`,
          dataStyle: 'text',
        },
      ]
    },
    {
      title: '灵魂拷问20：你知道企业的数字化管理才是企业走出困境的金钥匙吗？',
      color: '#FF4738',
      content: [
        {
          main: '在当下的市场中，企业拼的是综合实力。',
          dataStyle: 'title',
        },
        {
          main: `在当下的市场中，单一的技术能力已经无法占有一席之地，只有综合实力强，才能站稳市场。在如下方面，企业都需要加强管理，分为是：市场销售力、成本控制力、产品研发力、项目服务力、经营管控力、人才培养力，这些方面缺一不可，只有全部都提升到一个高度，企业才能真正的在市场中存活，并且可以持续的发展下去。`,
          dataStyle: 'text',
        },
        {
          main: '不要让信息孤岛再次出现。',
          dataStyle: 'title',
        },
        {
          main: `以往的企业往往是单独建立信息化系统，多年以后发现单一系统出现老旧情况，已无法满足企业业务需求，并且不能升级。多个系统之间有业务关联，但又由于技术的差异没办法将信息打通。各个系统都成了信息孤岛。在如今的社会中，不要让信息孤岛再次出现，企业应该寻找一家可以提供一套完整的经营管理产品，并且该产品是与各个客户共同发展，持续更新的产品，让企业拿到的是企业信息化的一揽子解决方案，这样的产品对于企业来说，才是真正需要的产品。`,
          dataStyle: 'text',
        },
      ]
    },
  ]
};

export default Datas;