<!--
 * @Descripttion: 内部点击切换的内容
 * @Author: 张明尧
 * @Date: 2021-02-24 10:32:20
 * @LastEditTime: 2021-03-09 13:26:30
-->
<template>
  <div class="cut-main">
    <div class="cut-main-list" v-for="(data, _data) in listMain" :key="_data">
      <div v-if="_data == listCut">
        <div class="cut-main-title">
          {{data.title}}
        </div>
        <div class="cut-main-title-bottom"></div>
        <div class="font-16 cut-main-jjfa">❤️ 解决方案：</div>
        <div v-for="(content, _content) in data.content" :key="_content" class="cut-main-content">
          <div v-if="content.dataStyle == 'title'"  class="cut-main-content-title">
            {{ content.main }}
          </div>
          <div v-if="content.dataStyle == 'text'"  class="cut-main-content-text" v-html="content.main">
          </div>
          <div style="min-width: 1300px;" v-if="content.dataStyle == 'array'"  class="cut-main-content-arr">
            <div class="cut-main-content-arr-content" v-for="(arr, _arr) in content.main" :key="_arr">
              <div class="cut-main-content-arr-name">
                <i class="jeicon jeicon-me2-on"></i>
                {{ arr.name }}
              </div>
              <div class="cut-main-content-arr-main">
                {{ arr.text }}
              </div>
            </div>
          </div>
          <div v-if="content.dataStyle == 'img'"  class="cut-main-content-img">
            <img :src="content.main" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datas from '../js/common'
export default {
  props: {
    listCut: {
      type: Number,
      default: () => (0),
    },
  },
  data() {
    return {
      listMain: Datas.list_datas,
    }
  }
}
</script>

<style lang="scss" scoped>
  .cut-main {
    color: #3f3f3f;
    margin-bottom: 100px;
    .font-16 {
      font-size: 16px;
    }
    .cut-main-content {
      .cut-main-content-title {
        height: 40px;
        line-height: 40px;
        padding-left: 20px;
        font-size: 16px;
        position: relative;
        font-weight: 600;
        margin: 10px 0;
        &::before {
          content: ' ';
          position: absolute;
          left: 5px;
          top: 50%;
          margin-top: -2.5px;
          width: 5px;
          height: 5px;
          background-color: #3f3f3f;
          border-radius: 50%;
        }
      }
      .cut-main-content-arr {
        .cut-main-content-arr-content {
          display: flex;
          flex-wrap: nowrap;
          font-size: 15px;
          height: 40px;
          line-height: 40px;
          .cut-main-content-arr-name {
            font-weight: 600;
            i {
              color: #F19B2C;
              margin-left: 10px;
            }
          }
        }
      }
      .cut-main-content-text {
        line-height: 40px;
        font-size: 16px;
      }
      .cut-main-content-img {
        margin: 20px 0;
        text-align: center;
        img {
          width: 80%;
          height: auto;
        }
      }
    }
    .cut-main-title {
      font-size: 30px;
      font-weight: 600;
    }
    .cut-main-jjfa {
      margin-top: 20px;
    }
    .cut-main-title-bottom {
      height: 3px;
      margin-top: 10px;
      border-bottom: 2px dashed #8D98AB;
    }
  }
</style>